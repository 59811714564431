import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Want to know the common paths in your chatbot or voice skill?`}</h2>
    <p>{`With Dashbot’s new Conversation Paths report, you can quickly navigate the common flows through your chatbot or voice skill.`}</p>
    <p>{`Quickly see drop off and abandonment as well as the paths that lead to conversion.`}</p>
    <p>{`The tree like navigation can be expanded to drill in further into the conversation.`}</p>
    <p>{`Hover over any node to get richer information, including the percentage of users who arrive at the node, or exit after the node.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/04/30163009/conversation-flow.gif",
        "alt": null
      }}></img></p>
    <p>{`Conversation Paths enable you to better understand user behavior and improve the user experience to increase conversion and reduce drop offs.`}</p>
    <h2>{`Get Started`}</h2>
    <p><a parentName="p" {...{
        "href": "https://reports.dashbot.io/signup"
      }}>{`Sign up`}</a>{` to get started with Conversation Paths!`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot and voice specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      